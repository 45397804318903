.ant-card {
  box-shadow: 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1),
    0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05);
}
.ant-notification
  .ant-notification-notice
  .ant-notification-notice-icon-success.anticon {
  color: #007a5c !important;
}
.ant-notification
  .ant-notification-notice
  .ant-notification-notice-icon-info.anticon {
  color: #2aacbb !important;
}
.ant-notification
  .ant-notification-notice
  .ant-notification-notice-icon-warning.anticon {
  color: #f27522 !important;
}
.ant-btn:has(.ant-btn-icon) {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
}
.ant-btn > a,
.ant-btn > a:hover {
  color: currentColor !important;
}
.ant-modal.media-modal .ant-modal-header {
  padding: 20px 24px 0;
}
.ant-modal.media-modal .ant-modal-content {
  padding: 0 !important;
}
.ant-modal.media-modal .ant-modal-footer {
  margin: 0;
  border-top: 1px solid #e2e8f0;
  padding: 20px 24px;
}
