.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f2f2f2;
  z-index: 1000;
}

.loadingBar {
  height: 100%;
  background-color: rgba(22, 166, 121, 1);
  transition: width 0.5s ease-in-out;
}
